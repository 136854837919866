/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Layout, Space } from 'antd';
const { Header, Footer, Sider, Content } = Layout;
import { SideNav, NavItem } from './sidenav';
import { SideNavProps } from './sidenav';
import Image from "./image";
import {collectAuthors, collectCategories, collectTags, createSlug} from "../components/blog/blog-helpers"
import { BlogNode } from "./blog/blog-types";
import SiteNavMenu from "./nav-menu";


function createSideNav(posts: BlogNode[]): NavItem[] {
  const navItems: NavItem[] = [];
  const iIndex: number = 0;
  const categories = collectCategories(posts);
  if (categories.length > 0) {
    const basePath = "/category/";
    const aChildren = categories.map(category => new NavItem(createSlug(category), category, [], basePath + createSlug(category)));
    navItems.push(
      new NavItem("categories", "Categories", aChildren));
  }
  const tags = collectTags(posts);
  if (tags.length > 0) {
    const basePath = "/tag/";
    const aChildren = tags.map(tag => new NavItem(createSlug(tag), tag, [], basePath + createSlug(tag)));
    navItems.push(
      new NavItem("tags", "Tags", aChildren));
  }
  const authors = collectAuthors(posts);
  if (authors.length > 0) {
    const basePath = "/author/";
    const aChildren = authors.map(author => new NavItem(createSlug(author), author, [], basePath + createSlug(author)));
    navItems.push(
      new NavItem("authors", "Authors", aChildren));
  }
  return navItems;
}

const PageLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: {
              regex: "/\/blog\//"
          }
        }
      ) {
        nodes {
            frontmatter {
                author
                category
                tags
            } 
        }
      }
    }
  `)
  const aNavItems = createSideNav(data.allMarkdownRemark.nodes)
  return (
    <>
      <Layout style={{minHeight:"100vh"}}>
        <Header style={{height: "15rem", padding: 0, margin: 0, background: "#333"}}>
          <Image />
        </Header>
        <Header style={{background: "#1D1D1D", color: "white"}}>
          <SiteNavMenu />
        </Header>
        <Layout>
          <Sider style={{background: "white", color: "white"}}>
            <SideNav items={aNavItems}></SideNav>
          </Sider>
          <Content>
            <main>{children}</main>
          </Content>
        </Layout>
        <Footer style={{background: "#1D1D1D", color: "white"}}>
            <p>© {new Date().getFullYear()} Cicero's Tenant</p>
        </Footer>
    </Layout>
    </>
  )
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default PageLayout
