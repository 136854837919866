import React from "react"
import { Layout, Menu, Breadcrumb } from 'antd';
import Item from "antd/lib/list/Item";
import { Link } from "gatsby";
const { SubMenu } = Menu;

class NavItem {
    constructor(
        public key: string,
        public label: string,
        public children: NavItem[],
        public path: string = "",
        public expanded: boolean = true) {}
};

interface SideNavProps {
    items: NavItem[]
}

function renderNavItem(item: NavItem) {
    if (item.children.length > 0) {
        const defaultOpenKeys = item.children.map(item => item.key);
        return (
            <SubMenu key={item.key} title={item.label} defaultOpenKeys={defaultOpenKeys}>
                {
                    item.children.map((subItem) => renderNavItem(subItem))
                }
            </SubMenu>
        )
    } else {
        return (
            <Menu.Item key={item.key}>
                <Link key={`${item.key}-link`} to={item.path}>{item.label}</Link>
            </Menu.Item>
        )
    }
}
const SideNav = ({ items }: SideNavProps) => {
    return (
        <div className="sticky-side-nav">
            <Menu mode="inline" title="Menu" defaultOpenKeys={['2']} style={{height: "100%"}}>
                { items.map(item => renderNavItem(item)) }
            </Menu>
        </div>
    )
}

export { SideNav, NavItem }
export type { SideNavProps }
