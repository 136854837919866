import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const Image = (image: string) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "ciceros_tenant_header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.placeholderImage?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  return (
    <div style={{height: "100%"}}>
      <div className="header" style={{width: "100%", position: "absolute", zIndex: 2}}>
        <div style={{background: "#1d1d1d", textAlign: "center",  margin: "0 auto", width: "25%", minWidth: "275px", maxWidth: "350px"}}>
          <h1 style={{color: "white", margin: 0, padding: 0 }}>Cicero's Tenant</h1>
          <div style={{borderTop: "3px solid rgba( 255, 255, 255, 0.5 )", display: "inline-block"}}>
            <p style={{color: "rgba( 255, 255, 255, 0.5 )", margin: 0, padding: 0}}>New perspectives from old ideas</p>
          </div>
        </div>
      </div>
      <Img fluid={{...data.placeholderImage.childImageSharp.fluid, aspectRatio: 1.4}}
          style={{maxHeight: "15rem", width: "100%", height: "100%"}}
          imgStyle={{ objectFit: 'fit' }} />
    </div>
  )
}

export default Image
