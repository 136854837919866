import { BlogNode } from "./blog-types"

function collectCategories(posts: BlogNode[]): string[] {
    const categories: Set<string> = new Set();
    posts.forEach(post => {
        if (post.frontmatter.category && post.frontmatter.category.trim()) {
            post.frontmatter.category.split(",").forEach(c => categories.add(c))
        }
    });
    return new Array(...categories);
}

function collectAuthors(posts: BlogNode[]): string[] {
    const categories: Set<string> = new Set();
    posts.forEach(post => {
        if (post.frontmatter.author && post.frontmatter.author.trim()) {
            post.frontmatter.author.split(",").forEach(a => categories.add(a))
        }
    });
    return new Array(...categories);
}

function collectTags(posts: BlogNode[]): string[] {
    const categories: Set<string> = new Set();
    posts.forEach(post => {
        if (post.frontmatter.tags && post.frontmatter.tags.trim()) {
            post.frontmatter.tags.split(",").forEach(t => categories.add(t))
        }
    });
    return new Array(...categories);
}

function createSlug(name: string) {
    return name.replace(/\\s+/, "-").replace(/[^A-z-]/g, "").trim();
}

export {collectAuthors, collectCategories, collectTags, createSlug}
