import React from "react"
import { Menu } from "antd"
import { Link } from "gatsby"

const SiteNavMenu = () => {
    return (
        <div className="site-nav-menu">
            <Menu mode="horizontal" style={{background: "#1d1d1d", color: "white"}}>
                <Menu.Item key="home">
                    <Link to="/" color="white">Home</Link>
                </Menu.Item>
                <Menu.Item key="blog">
                    <Link to="/blog" color="white">Articles</Link>
                </Menu.Item>
                {/* <Menu.Item key="category">
                    <Link to="/category">Categories</Link>
                </Menu.Item> */}
                <Menu.Item key="about">
                    <Link to="/about">About</Link>
                </Menu.Item>
            </Menu>
        </div>
    )
}

export default SiteNavMenu
